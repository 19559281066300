import React from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import '../../styles/components/our-partners.scss'

const OurPartners = ({ headerTitle, link }) => {
  let images = useStaticQuery(graphql`{
  absolut: file(relativePath: {eq: "partners/Absolut.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 141, layout: CONSTRAINED)
    }
  }
  attikiodos: file(relativePath: {eq: "partners/AttikiOdos.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 141, layout: CONSTRAINED)
    }
  }
  corona: file(relativePath: {eq: "partners/Corona.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 141, layout: CONSTRAINED)
    }
  }
  harley: file(relativePath: {eq: "partners/Harley.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 141, layout: CONSTRAINED)
    }
  }
  levis: file(relativePath: {eq: "partners/Levis.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 141, layout: CONSTRAINED)
    }
  }
  redbull: file(relativePath: {eq: "partners/Redbull.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 141, layout: CONSTRAINED)
    }
  }
  sephora: file(relativePath: {eq: "partners/Sephora.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 141, layout: CONSTRAINED)
    }
  }
  sony: file(relativePath: {eq: "partners/Sony.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 141, layout: CONSTRAINED)
    }
  }
  swarovski: file(relativePath: {eq: "partners/Swarovski.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 141, layout: CONSTRAINED)
    }
  }
  wind: file(relativePath: {eq: "partners/Wind.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 141, layout: CONSTRAINED)
    }
  }
}
`)
  
  return (
    <article className="our-partners">
      <div className="container">
        <header>
          <h2 className="dec-header--underline">{headerTitle}</h2>
        </header>
        <section className="container medium">
          <div className="row">
            <div className="col-6 col-sm-2 offset-sm-1"><GatsbyImage
              image={images.absolut.childImageSharp.gatsbyImageData}
              alt="Absolut Vodka" /></div>
            <div className="col-6 col-sm-2"><GatsbyImage
              image={images.attikiodos.childImageSharp.gatsbyImageData}
              alt="Attiki Odos" /></div>
            <div className="col-6 col-sm-2"><GatsbyImage image={images.corona.childImageSharp.gatsbyImageData} alt="Corona" /></div>
            <div className="col-6 col-sm-2"><GatsbyImage
              image={images.harley.childImageSharp.gatsbyImageData}
              alt="Harley Davidson" /></div>
            <div className="col-6 col-sm-2"><GatsbyImage image={images.levis.childImageSharp.gatsbyImageData} alt="Levis" /></div>
            <div className="col-6 col-sm-2 offset-sm-1"><GatsbyImage image={images.redbull.childImageSharp.gatsbyImageData} alt="Redbull" /></div>
            <div className="col-6 col-sm-2"><GatsbyImage image={images.sephora.childImageSharp.gatsbyImageData} alt="Sephora" /></div>
            <div className="col-6 col-sm-2"><GatsbyImage image={images.sony.childImageSharp.gatsbyImageData} alt="Sony" /></div>
            <div className="col-6 col-sm-2"><GatsbyImage image={images.swarovski.childImageSharp.gatsbyImageData} alt="Swarovski" /></div>
            <div className="col-6 col-sm-2"><GatsbyImage image={images.wind.childImageSharp.gatsbyImageData} alt="Wind" /></div>
          </div>
        </section>
        {link &&
          <div className="text-align-right">
            <Link to="/our-clients/" className="btn--more">See all our clients&nbsp;<span>&rarr;</span></Link>
          </div>
        }
      </div>
    </article>
  );
}

export default OurPartners