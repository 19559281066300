import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import TextLoop from "react-text-loop";
import { Player } from '@lottiefiles/react-lottie-player';

import SocialIcons from '../common/SocialIcons';

import '../../styles/components/hero-big.scss'

const HeroAnimations = ( props ) => {
	const titleFull = props.headerTitle.join(' + ');
	const alt = titleFull.substring(0, 40);
	
	return (
		<article className="hero dec-section--box-top dec-section--box-bottom">
			<div className="container">
				<div className="row">
					<div className="col">
							<SocialIcons type={"light"} position={"relative"} />
							<div className="hero__header">
								<h1 className="visually-hidden">{titleFull}</h1>
								<div className="h1 d-flex flex-wrap justify-content-center text-center">
									<TextLoop mask>{props.headerTitle[0]}</TextLoop>
									{props.headerTitle[1] &&
										<>
											<span>&nbsp;+&nbsp;</span>
											<TextLoop interval={4500} mask>{props.headerTitle[1]}</TextLoop>
										</>
									}
									{props.headerTitle[2] &&
										<>
											<span>&nbsp;+&nbsp;</span>
											<TextLoop interval={6000} mask>{props.headerTitle[2]}</TextLoop>
										</>
									}
								</div>
								<p>{props.headerSubtitle}</p>
							</div>
					</div>
				</div>
				<div className="row align-items-center justify-content-center hero__cta">
					<div className="col-md-6">
						<div className="hero__cta-image">
							<Player
                  src="/Build-Product-Colored.json"
                  autoplay
                  loop
                  style={{ marginTop: '12%' }}
                />
						</div>
					</div>
					<div className="col-md-6">
						<div className="hero__cta-content">
							<h2 dangerouslySetInnerHTML={{ __html: props.ctaTitle }} />
							<p dangerouslySetInnerHTML={{ __html: props.ctaText }} />
							{props.ctaButton
								? <Link to={`/${props.ctaSlug}`} className="btn--more">{props.ctaButton}&nbsp;<span>&rarr;</span></Link>
								: null
							}
						</div>
					</div>
				</div>
			</div>
		</article>
	)
}

export default HeroAnimations;
