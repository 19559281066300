import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/common/Layout'
import { MetaData } from '../components/common/meta'
import ContactForm from '../components/sections/contact-form'
import HeroAnimations from '../components/sections/hero-animations'
import OurServices from '../components/sections/our-services'
import OurExpertiseBoxes from '../components/sections/our-expertise-boxes'
import SectionHeader from '../components/common/section-header'
import PortfolioMasonry from '../components/sections/portfolio-masonry'
import OurPartners from '../components/sections/our-partners'
import Testimonials from '../components/sections/testimonials-static'

import schema from '../utils/schema/general'

/**
* Main index page (home page)
**/
const Index = ({ data, location }) => {
  const posts = data.indexPosts.edges

  return <>
    <MetaData location={location} />
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema.localBusiness)}</script>
      <script type="application/ld+json">{JSON.stringify(schema.organization)}</script>
    </Helmet>
    <Layout>
      <HeroAnimations
        headerTitle={[
					["Κατασκευή eshop","Κατασκευή ιστοσελίδων","Κατασκευή Shopify","Κατασκευή Wordpress"],
					["Διαφήμιση","Τηλεοπτικό","Παραγωγή βίντεο","Επαγγελματική φωτογράφιση"],
					["Email marketing","Social Media Marketing","Digital Marketing"]
				]}
        headerSubtitle={"You've built a great business. We're making sure people remember it!"}
        ctaTitle={"Δεν κατασκευάζουμε robot.<br><small>(<a href='/original-t-shirt/'>βέβαια...το κάναμε και αυτό</a>).</small>"}
        ctaText={"Κατασκευάζουμε eshop και ιστοσελίδες συνδυάζοντας τη τελευταία λέξη της τεχνολογίας, με την εμπειρία και τη δημιουργικότητα.<br>Με την ολοκλήρωση κάθε project, αναβαθμίζουμε τη συνεργασία μας, αναλαμβάνοντας τη προώθηση και επικοινωνία του!"}
        ctaButton={"ΔΕΙΤΕ ΤΑ ΕΡΓΑ ΜΑΣ"}
        ctaSlug={"our-work/"}
      />
      <article className="background__theme home-services">
        <OurServices decoration={true} subtitle={true} />
      </article>
      <OurExpertiseBoxes />
      <article className="container">
        <SectionHeader title="PORTFOLIO" text="Everything is designed.<br>Few things are designed well." />
        <PortfolioMasonry postList={posts} />
        <div className="portfolio-see-all">
          <Link to="/our-work/" title="See all works" className="btn--more">See all works &rarr;</Link>
        </div>
      </article>
      <OurPartners headerTitle="We are proud of our clients." link={true} />
      <Testimonials classname="background__theme" />
      <section className="container small py-5">
        <div className="text-align-center">
          <h2 className="dec-header--underline">We're Hiring</h2>
          <div className="py-5">
            <p>Headquartered in Athens (Greece), with teams in Munich (Germany), London (UK) and Pune (India). At Think Plus, we're on a mission to solve the biggest, most intractable challenges of enterprise-grade web applications and e-commerce solutions.<br/> If you share our passion join us and let's build this!</p>
            <a href="https://thinkplus.join.com/" className="btn__theme--inverted m-2" title="Find a job position in Think Plus" target="_blank" rel="noopener noreferrer nofollow">Join Us</a>
            <Link to="/about-us/" className="btn__theme m-2">Meet the Team</Link>
          </div>
        </div>
      </section>
      <ContactForm />
    </Layout>
  </>;
}

export default Index

export const pageQuery = graphql`{
  indexPosts: allGhostPost(
    sort: {order: DESC, fields: [published_at]}
    filter: {tags: {elemMatch: {name: {eq: "#portfolio"}}}}
    limit: 9
  ) {
    edges {
      node {
        ...GhostPostFields
      }
    }
  }
}
`
