import React from 'react'
import { Link } from 'gatsby'

import '../../styles/components/our-expertise-boxes.scss'

class OurExpertiseBoxes extends React.Component {
    render() {
        return (
            <section className="our-expertise-boxes">
                <div className="container medium">
                    <div className="row text-align-center">
                        <div className="col">
                            <h2 className="dec-header--underline">Η εξειδίκευσή μας</h2>
                            <div className="py-5">
                                <p>Πιστεύουμε πως αξίζετε περισσότερα από μια προκατασκευασμένη επιλογή. Για το λόγο αυτό, εστιάζουμε όλες μας τις δυνάμεις σε ένα πράγμα, τη δημιουργία κορυφαίων project που αποτελούν case study για κάθε κλάδο.</p>
                                <p>Δείτε παρακάτω μια σύντομη λίστα με τα σημεία που υπερέχουμε.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="grid-container--three expertise-boxes__container">
                                <div className="expertise-box">
                                    <h3 className="h4">BY INDUSTRY</h3>
                                    <ul className="unformatted-list">
                                        <li><Link to="/i-think-plus-lansarei-to-neo-eshop-toy-ikonomakis-gr-ependyontas-sto-shopify/">Eμπόριο καλλυντικών</Link></li>
                                        <li><Link to="/avgerinos-cosmetics-neo-diafimistiko/">Λιανεμπόριο</Link></li>
                                        <li><Link to="/i-think-plus-paroysiazei-to-neo-eshop-toy-kosmimatopoleioy-goldy/">Κοσμήματα</Link></li>
                                        <li><Link to="/absolut-me/">Αλκοολούχα Ποτά</Link></li>
                                        <li><Link to="/droulias-brothers-web-design/">Μουσική βιομηχανία</Link></li>
                                        <li><Link to="/i-think-plus-paroysiazei-to-neo-eshop-toy-ymk-group-youknowme-gr/">Εμπόριο ρούχων</Link></li>
                                        <li><Link to="/i-think-plus-paroysiazei-to-neo-entyposiako-eshop-toy-de-sunglasses/">Οπτικά</Link></li>
                                    </ul>
                                </div>
                                <div className="expertise-box">
                                    <h3 className="h4">BY CAPABILITY</h3>
                                    <ul className="unformatted-list">
                                        <li><Link to="/digital-marketing/">Digital Marketing</Link></li>
                                        <li><Link to="/kataskeyi-istoselidon/">Κατασκευή ιστοσελίδων</Link></li>
                                        <li><Link to="/kataskeyi-eshop-me-shopify/">Κατασκευή eshop</Link></li>
                                        <li><Link to="/think-videography/">Δημουργία βίντεο</Link></li>
                                        <li><Link to="/pro-photography/">Eπαγγελματική φωτογράφιση</Link></li>
                                        <li><Link to="/social-media-marketing/">Social Media Marketing</Link></li>
                                    </ul>
                                </div>
                                <div className="expertise-box">
                                    <h3 className="h4">BY PLATFORM</h3>
                                    <ul className="unformatted-list">
                                        <li><Link to="/wordpress-website/">Κατασκευή Wordpress</Link></li>
                                        <li><Link to="/kataskeyi-eshop-me-shopify/">Κατασκευή Shopify</Link></li>
                                        <li><Link to="/mailchimp-certified-experts-athens-greece/">Καμπάνιες Mailchimp</Link></li>
                                        <li><Link to="/facebook-ads/">Διαφημίσεις Facebook</Link></li>
                                        <li><Link to="/google-ads-ppc-management/">Διαφημίσεις Google</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default OurExpertiseBoxes