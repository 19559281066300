import React from 'react'
import FeatureImage from '../common/FeatureImage'
import '../../styles/components/testimonials-card.scss'

class TestimonialsCard extends React.Component {
  render() {
    return (
      <section className="testimonials-card">
        <FeatureImage 
          item={this.props.post}
          alt={this.props.post.person}
          imageClass='testimonials-card__image'
          lazyload='lazy'
        />
        {
          this.props.post.person
            ? <div className="testimonials-card__excerpt">
              <p className="testimonials-card__excerpt-body" dangerouslySetInnerHTML={{ __html: this.props.post.html}} />
              <p dangerouslySetInnerHTML={{ __html: this.props.post.person }} />
            </div>
            : <div dangerouslySetInnerHTML={{ __html: this.props.post.html }} className="testimonials-card__excerpt"></div>
        }
      </section>
    )
  }
}

export default TestimonialsCard