module.exports = [
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/testimonials/3drevolution.jpg",
			html: "«Θα θέλαμε να σας ευχαριστήσουμε όλους και όλες γι΄ αυτό το υπέροχο αποτέλεσμα που έχουμε μπροστά μας. Πραγματικά η συνεργασία ήταν υπέροχη, όπως και το περιμέναμε φυσικά.<br> Από το team της 3d revolution ξανά ένα μεγάλο ευχαριστώ!»",
			person: "Παπαδημητράκης Μιχάλης<br> Owner 3DRevolution.gr"
		}
	},
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/testimonials/nikolas-loumidis.jpeg",
			html: "«H Think Plus αποτέλεσε στρατηγική επιλογή συνεργάτη στην προσπάθεια μας να λανσάρουμε το ηλεκτρονικό μας κατάστημα. Η ομάδα έχει πολύ μεγάλη εμπειρία στo ηλεκτρονικό εμπόριο και ειδικά στην πλατφόρμα Shopify και μπορέσαμε γρήγορα και αποτελεσματικά να έχουμε ένα όμορφο και λειτουργικό eshop. Μείναμε πολύ ευχαριστημένοι με τη δουλειά τους και για τον λόγο αυτό δρομολογούμε την διεύρυνση της συνεργασίας μας και σε άλλες υπηρεσίες.»",
			person: "Nikolas Loumidis<br> Senior Manager LOUMIDIS COFFEE SHOPS SA"
		}
	},
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/testimonials/maya-hennerkes.jpeg",
			html: "<em>“The current COVID crisis puts the spotlight on the relevance of this collaboration between Think Plus, EBRD, HRADF and Global Sustain. The ESG tool will allow HRADF and other financial institutions going forward to address three important trends for a post-COVID world: ESG Integration will build resilience and sustainability into the investment process. Digitalisation will enable agile, efficient and remotely accessible environmental and social data management.”</em><br><br>Let me also take advantage to thank you for your tireless work on this beautiful project. I look very much forward to continue working with you and scaling this up now so that our banking and private equity clients can benefit at large from the tool.",
			person: "Maya Hennerkes<br> Sector Lead Financial Intermediaries<br> Environment and Sustainability Department<br> European Bank for Reconstruction and Development"
		}
	},
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/testimonials/spanos-michael-ceo-global-sustain.jpg",
			html: "«Excellent job, in every field. Think Plus is the best partner in the digital era.»",
			person: "Spanos Michael<br> CEO Global Sustain"
		}
	},
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/testimonials/again-1.jpg",
			html: "«Δημιουργική ομάδα με συνέπεια. Εξαίρετη δουλειά!»",
			person: "Μαρκόπουλος Mάρκος<br> CEO Again"
		}
	},
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/testimonials/harry-kalyvas-ceo-clip-talk.jpg",
			html: "«Top level work, nothing like a typical agency..! They should move to the States!!»",
			person: "Kalyvas Charalampos<br> CEO Clip&Talk"
		}
	},
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/testimonials/evangelos-alimpertis-ceo-gold-led.jpg",
			html: "«Συνέπεια και επαγγελματισμός, ανεξαρτήτως συνθηκών! Συγχαρητήρια στην ομάδα!»",
			person: "Αλιμπέρτης Ευάγγελος<br> CEO Gold Led"
		}
	},
	// {
	// 	item: {
	// 		title: "Testimonials",
	// 		feature_image: "/images/testimonials/tsichlopoulos-sotiris-founder-to-pagoto.jpg",
	// 		html: "«Eίναι θαυμάσιο να βλέπεις μία υπέροχη ιδέα να υλοποιείται εξίσου υπέροχα και τελικά να δίνει στο brand ακόμα περισσότερα απ’ αυτά που ίσως περίμενες. Εξαιρετική ομάδα!»",
	// 		person: "Τσιχλόπουλος Σωτήρης<br> Founder To Pagoto"
	// 	}
	// },
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/icons/avatar-female.jpg",
			html: "«Think Plus is a great partner to work with, both in video production and demanding web services. They always deliver on time with the best quality possible.»",
			person: "Dimopoulou Ioanna<br> Marketing Director Incadea GmbH"
		}
	},
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/testimonials/vassilis-chalkias-hellastron.jpg",
			html: "«Ο φορέας «ΕΛΛΗΝΙΚΕΣ ΥΠΟΔΟΜΕΣ και ΟΔΟΙ ΜΕ ΔΙΟΔΙΑ», με το διακριτικό τίτλο «HELLASTRON»(HELLENIC ASSOCIATION of TOLL ROAD NETWORK), ιδρύθηκε στα τέλη του 2014 με τη συμμετοχή όλων των σύγχρονων αυτοκινητοδρόμων και υποδομών με διόδια που λειτουργούν στην Ελλάδα. Σαν νεοσύστατος φορέας, είχαμε την ανάγκη υποστήριξης στην ηλεκτρονική επικοινωνία από σωστούς επαγγελματίες. Τόσο η ιστοσελίδα της HELLASTRON στο διαδίκτυο όσο και η σελίδες της στα SocialMedia έχουν την υπογραφή της Think Plus, ενός συνεργάτη που άκουσε με προσοχή και κατανόησε τις ανάγκες μας και τις προτεραιότητές μας και με βάση αυτές προχώρησε στο σχεδιασμό και υλοποίηση με αποτέλεσμα να είμαστε ιδιαίτερα ικανοποιημένοι.»",
			person: "Βασίλης Χαλκιάς<br> Πρόεδρος ΔΣ Hellastron"
		}
	},
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/testimonials/konstantopoulos-panos-marketing-director-stoiximan.jpg",
			html: "«Η ομάδα της Τhink Plus συνδυάζει την εμπειρία τόσο στο digital marketing, όσο και στη παραγωγή αξιόλογου περιεχομένου. Η συνεργασία μας στη παραγωγή τηλεοπτικού υλικού κρίθηκε άκρως επιτυχημένη, καθώς το τελικό αποτέλεσμα ήταν εξαιρετικό.»",
			person: "Κωνσταντόπουλος Πάνος<br> Μarketing Director stoiximan.gr"
		}
	},
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/testimonials/dramontanis-savvalas-publishing.jpg",
			html: "«Πολύτιμοι συνεργάτες! Στη Think Plus είναι γνώστες της λειτουργίας των social media, ιδιαίτερα προσαρμοστικοί στην ιδιαίτερη λογική, φιλοσοφία και αισθητική της εταιρείας μας, γρήγοροι και διαθέσιμοι να ανταποκριθούν σε κάθε μας αίτημα. Συμβάλλουν αποφασιστικά στο αποτελεσματικό «χτίσιμο» και την προώθηση της εταιρικής μας εικόνας στα κοινωνικά δίκτυα, στη σωστή ενημέρωση του κοινού μας και στην προσέγγιση νέου κοινού.»",
			person: "Δραμουντάνης Νίκος<br> Publishing Department Manager Εκδοτικός Οίκος Σαββάλας"
		}
	}
	,
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/testimonials/vassilis-panou-panoutron.jpg",
			html: "«Η εταιρεία μας βρήκε το συνεργάτη που επιθυμούσε στην ομάδα της Τhink Plus. Mε συνέπεια και επαγγελματισμό προσφέρει λύσεις στις τεχνολογικές & δημιουργικές ανάγκες τόσο για την Ελληνική όσο και για τις εταιρείες μας του εξωτερικού.»",
			person: "Πάνου Βασίλειος<br> CEO Panou & Panoutron"
		}
	},
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/icons/avatar-female.jpg",
			html: "Συγχαρητήρια σε όλη την ομάδα της THINK+. Η τεχνογνωσία, ο επαγγελματισμός και η αμεσότητα του ανθρώπινου δυναμικού μας βοηθάει και μας στηρίζει ακόμα και σε δύσκολες περιόδους με ιδιαίτερο φόρτο εργασίας και απαιτήσεις.Τόσο για την ταχύτατη επίλυση θεμάτων, όσο και για το ιδιαίτερο αίσθημα ασφάλειας που απολαμβάνουμε, σας αξίζει ένα μεγάλο ευχαριστώ!",
			person: "Κυριακοπούλου Αγγελική<br> NEUROSERVICES"
		}
	},
	{
		item: {
			title: "Testimonials",
			feature_image: "/images/testimonials/kokosioulis-dynamic-vision.jpg",
			html: "Dear Think Plus team, On behalf of the Dynamic Vision team we would like to thank you for a video delivered on time and of very high standards. Your job has already made an impact on the Interreg MED Programme and is expected to do make our Green Growth Community more visible to relevant stakeholders in the following transnational events that are going to take place the next years. Waiting for future collaborations",
			person: "Dr. Dimitrios Kokosioulis<br> Dynamic Vision"
		}
	}
]

// {
// 	item: {
// 		title: "Testimonials",
// 		feature_image: "/images/testimonials/",
// 		html: "",
//		person: ""
// 	}
// }
