import React from "react"
import Slider from 'react-slick'
import TestimonialsCard from "../common/TestimonialCard"
import '../../styles/components/testimonials.scss'

import testimonials from '../../utils/testimonials'

export default ({ classname }) => {
  var settings = {
    dots: true,
    arrows: false,
    lazyload: "ondemand",
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "testimonials__slider",
  };

  return (
    <article className={`testimonials ${classname && classname}`}>
      <div className="container">
      <h2 className="testimonials-card__title h1">Testimonials</h2>
        <Slider {...settings}>
          {testimonials.map(({ item, i }) => (
            <TestimonialsCard key={`testimonialCard${i}`} post={item} />
          ))}
        </Slider>
      </div>
    </article>
  )
}
